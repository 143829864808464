import { Injectable } from '@angular/core';
import { Subject } from 'rxjs';

@Injectable({
  providedIn: 'root'
})
export class AppService {

  private triggerSource = new Subject<void>();
  private triggerLogout = new Subject<void>();
  private triggerSessionTimer = new Subject<void>();
  triggerEvent$ = this.triggerSource.asObservable();
  triggerLogout$ = this.triggerLogout.asObservable();
  triggerSessionTimer$ = this.triggerSessionTimer.asObservable();

  triggerLoadMenuItem() {
    this.triggerSource.next();
  }
  
  triggerRemoveMenu() {
    this.triggerLogout.next();
  }
  
  triggerStartSessionTimer() {
    this.triggerSessionTimer.next();
  }
}
