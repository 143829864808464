import { Pipe, PipeTransform } from '@angular/core';

@Pipe({
  name: 'emailMask'
})
export class EmailMaskPipe implements PipeTransform {

  transform(email: string): string {
    if (!email) return '';

    const parts = email.split('@');
    const username = parts[0];
    const domainParts = parts[1].split('.');
    const domain = domainParts[0];
    const maskedUsername = this.maskString(username, 2); // Mask all characters except the first 2
    const maskedDomain = this.maskString(domain, 3); // Mask all characters except the first 3

    return `${maskedUsername}@${maskedDomain}.${domainParts.slice(1).join('.')}`;
  }

  private maskString(str: string, visibleChars: number): string {
    const maskedCharacters = str.length - visibleChars;
    const visiblePart = str.substr(0, visibleChars);
    const maskedPart = '*'.repeat(maskedCharacters);
    return visiblePart + maskedPart;
  }
}
