/* eslint-disable prefer-const */
/* eslint-disable quote-props */
/* eslint-disable no-trailing-spaces */
/* eslint-disable @typescript-eslint/semi */
/* eslint-disable object-shorthand */
/* eslint-disable @typescript-eslint/no-inferrable-types */
/* eslint-disable @typescript-eslint/naming-convention */
import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { HttpClient, HttpHeaders, HttpParams } from '@angular/common/http';
import { environment } from 'src/environments/environment';
import { Email } from '../models/email.model';



@Injectable({
  providedIn: 'root'
})
export class UtilityService {

  constructor(private http: HttpClient) { }

  sendEmail(data: Email): Observable<boolean>{
    let params = new HttpParams();
    params = params.append('emailObj', JSON.stringify(data));
    return this.http.get(environment.apiBaseUrl+'user/email', {params: params}) as Observable<boolean>;
  }

}
