import { Component, OnInit } from '@angular/core';
import { FormControl, FormGroup, FormGroupDirective, Validators } from '@angular/forms';
import { Router } from '@angular/router';
import { finalize } from 'rxjs/operators';
import { AppService } from 'src/app/app.service';
import { Client } from 'src/app/models/client.model';
import { User } from 'src/app/models/user.model';
import { ApiService } from 'src/app/services/api.service';

@Component({
  selector: 'app-otp',
  templateUrl: './otp.component.html',
  styleUrls: ['./otp.component.scss'],
})
export class OtpComponent implements OnInit {

  otpForm: FormGroup;
  userBeingLoggedIn: User | null = null;
  isFormSubmitting: boolean = false;
  client: Client;
  errorMessage: string;
  successMessage: string;

  // Variabled defined for countdown timer on OTP
  countdownSeconds: number;
  minutes: number;
  seconds: number;
  interval: any;
  isAccountBlocked: boolean = false;

  constructor(
    private apiService: ApiService,
    private router: Router,
    private appService: AppService
  ) {
    this.errorMessage = null
    const sessionData = JSON.parse(sessionStorage.getItem("userDetails"))
    if (sessionData && sessionData.id) {
      this.userBeingLoggedIn = sessionData
    } else {
      window.location.href = '/#/auth';
      window.location.reload();
    }
  }

  ngOnInit() {
    this.otpForm = new FormGroup({
      otp: new FormControl('', [
        Validators.required,
        Validators.minLength(6),
        Validators.maxLength(6),
        Validators.pattern(/^\d+$/)
      ])
    });
    this.startTimer()
  }


  navigateToForgetPassword(): void {
    this.router.navigate(['/auth/forgotten-password'])
    this.isAccountBlocked = false;
  }


  async resendOtp(): Promise<void> {
    try {
      this.successMessage = "";
      this.errorMessage = "";
      const user = JSON.parse(sessionStorage.getItem("userDetails"))
      const response = await this.apiService.getNewOTPToken(user.userName).toPromise()
      this.successMessage = "OTP Sent Successfully!"
    } catch (error) {
      console.log({ error })
      this.errorMessage = error?.error?.error ? error?.error?.error : error.message ? error.message : error
    }
  }


  verifyOTP(): void {
    const { otp } = this.otpForm.value;


    this.errorMessage = "";
    this.successMessage = "";


    this.isFormSubmitting = true;
    this.apiService.verifyOTP({
      userId: this.userBeingLoggedIn.id,
      clientID: this.userBeingLoggedIn.clientID.toString(),
      otp: otp
    }).pipe(

      finalize(() => {
        setTimeout(() => {
          this.isFormSubmitting = false;
        });
      })
      // finalize(() => { this.isFormSubmitting = false })
    ).subscribe({
      next: response => {
        console.log({ response })

        const headers = response.headers;

        const refreshToken = headers.get('Refresh_token');
        const accessToken = headers.get('access_token');
        if (refreshToken) {
          localStorage.setItem("refresh", refreshToken)
        }

        if (accessToken) {
          localStorage.setItem("token", accessToken);
        }

        let res = response.body

        if (!res) {
          this.errorMessage = 'User login unsuccessful. Please try again.';
          return;
        } else if (typeof res === 'string') {
          console.log({ res })
          this.errorMessage = res;
          return;
        }

        if (res.firstName && res.lastName) {
          this.apiService.userFullName.next(res.firstName + ' ' + res.lastName);
          this.apiService.userName.next(res.userName);
          this.apiService.userId.next(res.userId as string);
          this.apiService.user.next(res);
          this.navigateIfLoggedIn();
        }


      },
      error: err => {
        console.log({ err })
        this.errorMessage = err.error.error
      }
    });
  }


  resentOtpToClient(): void {
    console.log({ user: sessionStorage.getItem("userDetails") })
    let user = sessionStorage.getItem("userDetails")
    if (user) {
      let jsonified: any = JSON.parse(user)
      let userName = jsonified.userName

      this.apiService.resendOtp({ userName }).subscribe({
        next: (data) => {
          console.log({ data })
        },
        error: (err) => {
          console.log({ err })
        }
      })
    }
  }
  private navigateIfLoggedIn(): void {
    // window.location.href = '/#/home';
    // window.location.reload();
    this.appService.triggerLoadMenuItem();
    this.router.navigate(['/home'])
  }




  // Countdown timer code starts
  startTimer() {
    this.countdownSeconds = 120; // Example: Initial countdown value in seconds (2 minutes)

    this.interval = setInterval(() => {
      if (this.countdownSeconds > 0) {
        this.convertSeconds(this.countdownSeconds);
        this.countdownSeconds--;
      } else {
        this.clearTimer();
        // Optionally, perform any action when countdown reaches zero
      }
    }, 1000); // Update every second (1000 milliseconds)
  }

  clearTimer() {
    clearInterval(this.interval);
  }

  convertSeconds(totalSeconds: number) {
    this.minutes = Math.floor(totalSeconds / 60);
    this.seconds = totalSeconds % 60;
  }
  // Countdown timer code starts



}
