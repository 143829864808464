import { Component, OnInit } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { ChpwComponent } from 'src/app/components/chpw/chpw.component';

@Component({
  selector: 'app-change-password',
  templateUrl: './change-password.component.html',
  styleUrls: ['./change-password.component.scss']
})
export class ChangePasswordComponent implements OnInit {

  token: string;                                                  // The token in the url
  isVerifyingToken: boolean;                                      // Flag to check if token verificaiton is in progress
  isTokenValid: boolean;                                          // Flag to check if token is valid or not, this will show or hide submit button 
  isAuthRoute: boolean;
  constructor(private route: ActivatedRoute, private router: Router) {
    this.token = null;
    this.isVerifyingToken = false;
    
    if(window.location.href.includes("/auth/change-password")){
      this.isAuthRoute = true;
      this.isTokenValid = false
    }else{
      this.isAuthRoute = false;
      this.isTokenValid = true
    }
  }

  ngOnInit() {
    if (this.route.snapshot.queryParamMap.keys.length > 0) {
      this.isTokenValid = true;

      const queryParams = this.route.snapshot.queryParamMap;
      this.token = queryParams.get("resetToken");

     
      // this.tokenVerificationHandler()
    }
  }


   // Verify if provided token has expired on valid
   tokenVerificationHandler = (): void => {
    try {
      this.isVerifyingToken = true
      this.isVerifyingToken = false
    } catch (err) {
      this.isVerifyingToken = false
    }
  }


  private navigateToForgetPassword(): void {
    this.router.navigate(['/auth/forgotten-password'])
  }

}
