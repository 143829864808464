import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { FormsModule } from '@angular/forms';
//import { BrowserAnimationsModule } from '@angular/platform-browser/animations';

import { IonicModule } from '@ionic/angular';

import { AuthPageRoutingModule } from './auth-routing.module';

import { AuthPage } from './auth.page';
import { MatToolbarModule } from '@angular/material/toolbar';
import { MatIconModule } from '@angular/material/icon';
import { MatButtonModule } from '@angular/material/button';
import { MatFormFieldModule } from '@angular/material/form-field';
import { MatInputModule } from '@angular/material/input';
import { ReactiveFormsModule } from '@angular/forms';
import { ChpwComponent } from 'src/app/components/chpw/chpw.component';
import { ChangePasswordComponent } from './change-password/change-password.component';
import { OtpComponent } from './otp/otp.component';
import { EmailMaskPipe } from 'src/app/email-mask.pipe';
import { ForgotPasswordComponent } from './forgot-password/forgot-password.component';

@NgModule({

  imports: [
    CommonModule,
    //BrowserAnimationsModule,
    FormsModule,
    IonicModule,
    AuthPageRoutingModule,
    MatToolbarModule,
    MatIconModule,
    MatButtonModule,
    MatFormFieldModule,
    MatInputModule,
    ReactiveFormsModule
  ],
  declarations: [AuthPage,
    ChpwComponent,
    ChangePasswordComponent,
    OtpComponent,
    EmailMaskPipe,
    ForgotPasswordComponent
  ]
})
export class AuthPageModule {}
