/* eslint-disable @typescript-eslint/no-shadow */
/* eslint-disable no-unused-labels */
/* eslint-disable @typescript-eslint/no-inferrable-types */
/* eslint-disable prefer-const */
import { Component, OnInit, OnDestroy } from '@angular/core';
import { FormControl, FormGroup, FormGroupDirective, Validators } from '@angular/forms';
import { ActivatedRoute, Router } from '@angular/router';
import { User, UserWithoutToken } from 'src/app/models/user.model';
import { ApiService } from 'src/app/services/api.service';
import { LoadingController } from '@ionic/angular';
import { Client } from 'src/app/models/client.model';
import { PersistedValueEnum } from 'src/app/models/persisted-value-enum';
import { Subscription } from 'rxjs';
import { catchError, finalize } from 'rxjs/operators';

enum AuthSubPage {
  LOGIN = 'login',
  FORGOT_PASSWORD = 'forgot-password',
  CHANGE_PASSWORD = 'change-password',
  OTP = 'otp',
}

@Component({
  selector: 'app-auth',
  templateUrl: './auth.page.html',
  styleUrls: ['./auth.page.scss'],
})
export class AuthPage implements OnInit, OnDestroy {

  forgotPassForm: FormGroup;
  loginForm: FormGroup;
  otpForm: FormGroup;
  changePasswordForm: FormGroup;

  isAccountBlocked: Boolean;
  hasLoginRequested: Boolean = false;
  loginError: string = ""

  disableDIV = true;
  hide = true;
  currentSubPage: AuthSubPage = AuthSubPage.LOGIN;
  errorCount: number = 0;
  authSub: Subscription;
  clientSub: Subscription;
  prSub: Subscription;
  routeQueryParamsSub: Subscription;
  client: Client;
  user: User;
  userBeingLoggedIn: User | null = null;
  AuthSubPage = AuthSubPage;
  errorMessage: string = '';
  successMessage: string = '';
  passwordVisible: boolean = false;
  isFormSubmitting: boolean = false;

  constructor(
    private apiService: ApiService,
    private router: Router,
    private loadingController: LoadingController,
    private route: ActivatedRoute) {
    //using the line below  as ngOnInit because ngOnInit not firing after route.navigate
    if (this.checkLoginUser() === true) {
      this.navigateIfLoggedIn();
    }


    this.isAccountBlocked = false;
  }

  get userName() {
    return this.loginForm.get('userName');
  }

  get password() {
    return this.loginForm.get('password');
  }

  get email() {
    return this.forgotPassForm.get('email');
  }

  public ngOnInit(): void {
    this.initializeForms();

    this.routeQueryParamsSub = this.route.queryParams.subscribe(params => {
      this.client = JSON.parse(localStorage.getItem(PersistedValueEnum.client));

      if (params.changePassword) {
        this.changeAuthSubPage(AuthSubPage.CHANGE_PASSWORD);
      }
    });
    // this.loginForm.patchValue({
    //   userName: "asadk6103@gmail.com",
    //   password: "efgeUO"
    // })
  }

  public ngOnDestroy(): void {
    this.authSub?.unsubscribe();
    this.clientSub?.unsubscribe();
    this.prSub?.unsubscribe();
    this.routeQueryParamsSub?.unsubscribe();
    this.errorCount = 0;
    this.errorMessage = ""
    this.loginError = ""
    this.forgotPassForm.reset()
    this.loginForm.reset()
    this.isAccountBlocked = false
    this.isFormSubmitting = false
    this.hasLoginRequested = false
    this.hide = false;
    this.successMessage = ""
  }

  public initializeForms(): void {
    this.loginForm = new FormGroup({
      userName: new FormControl('', [Validators.required, Validators.email]),
      password: new FormControl('', [Validators.required])
    });

    this.forgotPassForm = new FormGroup({
      email: new FormControl('', [Validators.required, Validators.email])
    });

    this.otpForm = new FormGroup({
      otp: new FormControl('', [
        Validators.required,
        Validators.minLength(6),
        Validators.maxLength(6),
        Validators.pattern(/^\d+$/)
      ])
    });

    this.changePasswordForm = new FormGroup({
      newPassword: new FormControl('', [
        Validators.required,
        Validators.minLength(6),
      ]),
      confirmPassword: new FormControl('', [
        Validators.required,
      ])
    }, {
      validators: (formGroup: FormGroup) => {
        const newPassword = formGroup.get('newPassword');
        const confirmPassword = formGroup.get('confirmPassword');
        if (newPassword && confirmPassword && newPassword.value !== confirmPassword.value) {
          confirmPassword.setErrors({ passwordMismatch: true });
        } else {
          confirmPassword.setErrors(null);
        }
        return null;
      }
    });
  }

  // public submitLogin(formData: any, formDirective: FormGroupDirective): void {
  //   if (!this.loginForm.valid || this.isFormSubmitting) {
  //     return;
  //   }

  //   try {
  //     let data = {
  //       password: this.password.value,
  //       userName: this.userName.value,
  //       clientID: this.client.clientID
  //     };

  //     this.hasLoginRequested = true;
  //     this.isFormSubmitting = true;
  //     this.authSub = this.apiService.authenticateUser(data)
  //       .pipe(
  //         finalize(() => {
  //           this.isFormSubmitting = false;
  //           this.hasLoginRequested = false;
  //         })
  //       )
  //       .subscribe((res: UserWithoutToken | string) => {
  //         if (res === null) {
  //           this.errorMessage = 'Invalid User Name';
  //         }
  //         else {
  //           if (res === 'Invalid Password') {
  //             this.errorMessage = 'Invalid Password';
  //           } else if (typeof res !== 'object') {
  //             this.errorMessage = 'User login unsuccessful. Please try again.';
  //             console.error('Expected a user object but got something else.');
  //           } else {
  //             this.userBeingLoggedIn = res as User;
  //             formDirective.resetForm();
  //             this.loginForm.reset();
  //             sessionStorage.setItem("userDetails", JSON.stringify(res))
  //             // this.changeAuthSubPage(AuthSubPage.OTP);
  //             // window.location.href = '/#/auth/otp';
  //             // window.location.reload();
  //             this.router.navigate(['/auth/otp'])
  //           }
  //         }
  //       });
  //   } catch (error) {
  //     this.client = JSON.parse(localStorage.getItem(PersistedValueEnum.client));
  //     console.log(this.client.clientID);
  //     this.errorCount++;
  //     if (this.errorCount < 4) {
  //       this.submitLogin(formData, formDirective);
  //     }
  //     else {
  //       console.error('Auth Submit error', error);
  //       this.errorCount = 0;
  //     }
  //   }
  // }


  async submitLogin(formData: FormData, formDirective: FormGroupDirective) {
    if (!this.loginForm.valid || this.isFormSubmitting) {
      return;
    }

    try {
      let data = {
        password: this.password.value,
        userName: this.userName.value,
        clientID: this.client.clientID
      };

      this.hasLoginRequested = true;
      this.isFormSubmitting = true;

      const res: any = await this.apiService.authenticateUser(data).toPromise();

      if (res.error) {
        this.errorMessage = res.error
      }

      if (res === null) {
        this.errorMessage = 'Invalid User Name';
      } else {
        if (res === 'Invalid Password') {
          this.errorMessage = 'Invalid Password';
        } else if (typeof res !== 'object') {
          this.errorMessage = 'User login unsuccessful. Please try again.';
          console.error('Expected a user object but got something else.');
        } else {
          this.userBeingLoggedIn = res as User;
          let expDateObj = new Date(res?.pwd_reset_at);
          expDateObj.setMonth(expDateObj.getMonth() + 1)
          const now = new Date();

          const dateDiff = expDateObj.getTime() - now.getTime();
          if (dateDiff <= 0) {
            this.isAccountBlocked = true;
          } else {
            formDirective.resetForm();
            this.loginForm.reset();
            sessionStorage.setItem("userDetails", JSON.stringify(res));
            this.router.navigate(['/auth/otp']);
          }

        }
      }
    } catch (error) {

      if (error.status === 429) {
        this.isAccountBlocked = true;
        this.loginForm.reset()
        this.errorMessage = ""
      }
      if (error?.error?.error) {
        this.errorMessage = error.error.error
      }
    } finally {
      this.isFormSubmitting = false;
      this.hasLoginRequested = false;
    }
  }

  public getSubdomain(): void {
    let subdomain = '';
    const domain = window.location.hostname;
    if (domain.indexOf('.') < 0 ||
      domain.split('.')[0] === 'localhost' || domain.split('.')[0] === 'lvh' || domain.split('.')[0] === 'www') {
      subdomain = 'test';
    } else {
      subdomain = domain.split('.')[0];
    }
    this.clientSub = this.apiService.getClient(subdomain).subscribe((res: Client | string) => {
      if (res != null) {
        try {
          const tempClient = res[0] as Client;
          this.client = tempClient;
          this.apiService.subDomain.next(tempClient.subdomain);
          this.apiService.client.next(tempClient);
          //this.clientID = tempClient.clientID;
        } catch {
          console.log(res);
        }
      }
    });
  }

  public passwordResetRequest(): void {
    let data = {
      email: this.forgotPassForm.value.email,
      clientID: this.client.clientID
    };

    this.isFormSubmitting = true;
    this.apiService.forgotPassword(data)
      .pipe(
        finalize(() => { this.isFormSubmitting = false }),
        catchError(error => {
          this.errorMessage = 'Oops! Something went wrong. Please try again.';
          throw error;
        })
      )
      .subscribe((res) => {
        this.successMessage = 'Password reset link has been sent to your email.';
      })
  }

  public checkLoginUser(): boolean {
    let result = true;
    try {
      this.client = JSON.parse(localStorage.getItem(PersistedValueEnum.client));
      this.user = JSON.parse(localStorage.getItem(PersistedValueEnum.user));
      if (this.client.clientID !== this.user.clientID) {
        localStorage.removeItem('user');
        localStorage.removeItem('userFullName');
        localStorage.removeItem('userName');
        result = false;
      }
    } catch {
      result = false;
    }

    return result;
  }

  changeAuthSubPage(page: AuthSubPage): void {
    console.log({ page })
    this.errorMessage = '';
    this.successMessage = '';
    this.currentSubPage = page;
  }

  // verifyOTP(): void {
  //   const { otp } = this.otpForm.value;

  //   this.isFormSubmitting = true;
  //   this.apiService.verifyOTP({
  //     userId: this.userBeingLoggedIn.id,
  //     clientID: this.client.clientID.toString(),
  //     otp: otp
  //   }).pipe(
  //     finalize(() => { this.isFormSubmitting = false })
  //   ).subscribe(res => {
  //     if (!res) {
  //       this.changeAuthSubPage(AuthSubPage.LOGIN);
  //       this.errorMessage = 'User login unsuccessful. Please try again.';
  //       return;
  //     } else if (typeof res === 'string') {
  //       this.errorMessage = res;
  //       return;
  //     }

  //     this.apiService.userFullName.next(res.firstName + ' ' + res.lastName);
  //     this.apiService.userName.next(res.userName);
  //     this.apiService.userId.next(res.userId as string);
  //     this.apiService.user.next(res);
  //     this.navigateIfLoggedIn();
  //   });
  // }

  cancelOTP(): void {
    this.otpForm.reset();
    this.userBeingLoggedIn = null;
    this.changeAuthSubPage(AuthSubPage.LOGIN);
  }

  submitChangePassword(): void {
    const { newPassword } = this.changePasswordForm.value;
    const resetToken = this.route.snapshot.queryParams.resetToken;

    this.isFormSubmitting = true;
    this.apiService.resetPassword({ password: newPassword, token: resetToken })
      .pipe(
        finalize(() => { this.isFormSubmitting = false })
      )
      .subscribe(res => {
        if (!res || !Array.isArray(res) || res.length === 0 || typeof res[0] !== 'string') {
          this.errorMessage = 'Password reset unsuccessful. Please try again.';
          return;
        } else if (res[0].toLowerCase().includes('invalid')) {
          this.errorMessage = 'Invalid reset token.';
          return;
        }

        this.changeAuthSubPage(AuthSubPage.LOGIN);
        this.router.navigate(['/auth'], { queryParams: { changePassword: null, resetToken: null }, queryParamsHandling: 'merge' });
        this.successMessage = `Password has been changed successfully. Please log in using the new password.`;
      });
  }

  private navigateIfLoggedIn(): void {
    window.location.href = '/#/home';
    window.location.reload();
  }

  private navigateToForgetPassword(): void {
    this.router.navigate(['/auth/forgotten-password'])
    this.isAccountBlocked = false;
  }

}
