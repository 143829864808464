import { Component, OnInit } from '@angular/core';
import { FormBuilder, FormControl, FormGroup, Validators } from '@angular/forms';
import { Router } from '@angular/router';
import { Client } from 'src/app/models/client.model';
import { PersistedValueEnum } from 'src/app/models/persisted-value-enum';
import { ApiService } from 'src/app/services/api.service';

@Component({
  selector: 'app-forgot-password',
  templateUrl: './forgot-password.component.html',
  styleUrls: ['./forgot-password.component.scss'],
})
export class ForgotPasswordComponent implements OnInit {

  forgotPasswordForm: FormGroup;
  isLoading: boolean;
  client: Client;
  errorMessage: string;
  successMessage: string;

  constructor(private apiService: ApiService,private router: Router) {
    this.errorMessage = "";
    this.successMessage = "";
  }

  ngOnInit() {
    this.initializeForms();
  }


  initializeForms = (): void => {
    this.forgotPasswordForm = new FormGroup({
      email: new FormControl("", [Validators.required, Validators.email])
    })

  }

  handleRequestPasswordLink = async (): Promise<void> => {
    try {
      this.isLoading = true;
      this.client = JSON.parse(localStorage.getItem(PersistedValueEnum.client));

      let data = {
        email: this.forgotPasswordForm.value.email,
        clientID: this.client.clientID
      };

      const res = await this.apiService.forgotPassword(data).toPromise();
      if (!res) {
        throw new Error(`error ${res}`)
      }
      this.successMessage = 'Password reset link has been sent to your email.'
      this.isLoading = false;
    } catch (err) {
      this.errorMessage = 'Oops! Something went wrong. Please try again.';
      console.error('Error occurred:', err);
    }
    finally {
      this.isLoading = false;
    }
  }


  navigateToLogin = ():void =>{
    // window.location.href = '/#/auth';
    // window.location.reload();
    this.router.navigate(['/auth/login'])
  }
}
