/* eslint-disable @typescript-eslint/naming-convention */
import { Component, OnInit } from '@angular/core';

@Component({
  selector: 'app-splashscreen',
  templateUrl: './splashscreen.component.html',
  styleUrls: ['./splashscreen.component.scss'],
})
export class SplashscreenComponent implements OnInit {

  constructor() { }

  ngOnInit() {}

  public Logo(): string{
      return '../assets/logo/aersurveyLogo.png';
  }

}
