/* eslint-disable eol-last */
/* eslint-disable @typescript-eslint/naming-convention */
export const PersistedValueEnum = Object.freeze({
  userFullName: 'userFullName',
  userName: 'userName',
  userId:'0',
  subDomain: 'subDomain',
  user: 'user',
  client: 'client'
});
